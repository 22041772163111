<template>
  <section id="faq">
    <!-- seach banner  -->
    <v-card
      flat
      class="faq-bg d-flex align-center justify-center text-center mb-9"
    >
      <v-card-text>
        <p class="tw-font-extrabold tw-text-3xl tw-text-blue-900">
          Meet Your Phox Cloud
        </p>
        <p class="tw-text-2xl">
          Learn the fundamentals of Phox
        </p>

        <v-form class="faq-search-input mx-auto">
          <v-text-field
            v-model="knowledgeBaseSearchQuery"
            outlined
            placeholder="Search videos...."
            hide-details
            class="faq-search-input"
          >
            <template #prepend-inner>
              <v-icon
                size="23"
                class="mx-1"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
            </template>
          </v-text-field>
        </v-form>
      </v-card-text>
    </v-card>
    <div
      id="video-container"
      :key="videoKey"
      ref="div1"
      class="tw-pt-2 tw-pb-4 tw-flex tw-justify-center"
    >
      <vue-vimeo-player
        ref="player"
        :options="options"
        :video-id="videoId"
        :player-height="height"
        @ready="onReady"
      ></vue-vimeo-player>
    </div>
    <div class="tw-pt-4 tw-grid tw-justify-items-stretch">
      <div class="tw-justify-self-center">
        <p class="tw-font-semibold tw-text-black tw-text-xl">
          {{ itemdesc }}
        </p>
      </div>
      <div class="tw-justify-self-center">
        <p class="tw-font-semibold tw-text-black tw-text-xl">
          {{ itemtitle }}
        </p>
      </div>
      <div class="tw-justify-self-center">
        <p class="tw-text-gray-300 tw-text-sm">
          {{ itemlength }}
        </p>
      </div>
    </div>
    <v-row class="tw-pt-8 kb-search-content-info match-height">
      <v-col
        v-for="item in filteredKB"
        :key="item.character"
        md="3"
        sm="6"
        cols="12"
        class="kb-search-content"
      >
        <v-card
          class="hover:tw-bg-gray-400 hover:tw-bg-opacity-5 "
          color="text-center cursor-pointer"
          @click.native="playVideo(item.videoId, item)"
        >
          <div class="tw-rounded-none tw-card tw-bordered tw-text-center tw-shadow-2xl">
            <div class="tw-rounded-none tw-card tw-image-full">
              <figure class="tw-bg-black">
                <v-img
                  contain
                  :max-width="item.characterSize"
                  class="tw-opacity-25 mx-auto align-center justify-start tw-w-full"
                  :src="item.character"
                ></v-img>
              </figure>
              <div class="tw-flex tw-content-start tw-justify-items-start tw-card-body">
                <p class="tw-flex tw-text-white tw-card-title tw-justify-start">
                  {{ item.title }}
                </p>
                <p class="tw-flex tw-flex-wrap tw-text-gray-400 tw-content-start tw-justify-items-start">
                  {{ item.subtitle }}
                </p>
              </div>
            </div>
            <div class="tw-pl-3 tw-pt-4 tw-grid tw-justify-items-stretch">
              <div class="tw-justify-self-start">
                <p class="tw-leading-none tw-font-semibold tw-text-black tw-text-base">
                  {{ item.desc }}
                </p>
              </div>
              <div class="tw-justify-self-start">
                <p class="tw-leading-none tw-font-semibold tw-text-black tw-text-base">
                  {{ item.title }}
                </p>
              </div>
              <div class="tw-justify-self-start">
                <p class="tw-leading-none tw-text-gray-300 tw-text-sm">
                  {{ item.length }}
                </p>
              </div>
            </div>
          <!-- <div class="tw-card-body">
            <h2 class="tw-card-title">
              Small image file
            </h2>
            <p>Rerum reiciendis beatae tenetur excepturi aut pariatur est eos. Sit sit necessitatibus veritatis sed molestiae voluptates incidunt iure sapiente.</p>
            <div class="tw-card-actions">
              <div class="tw-badge tw-badge-ghost">
                Article
              </div>
              <div class="tw-badge tw-badge-ghost">
                Photography
              </div>
            </div>
          </div> -->
          </div>
        </v-card>
      </v-col>
      <!-- no result found -->
      <v-col
        v-show="!filteredKB.length"
        cols="12"
        class="text-center"
      >
        <h4 class="mt-4">
          Search result not found!!
        </h4>
      </v-col>
    </v-row>

    <v-card-text class="text-center tw-pt-12">
      <p class="text-xl font-weight-medium text--primary mb-1">
        You still have a question?
      </p>
      <p>If you cannot find a question in our FAQ, you can always contact us. We will answer to you shortly!</p>
    </v-card-text>

    <v-row class="mx-auto">
      <v-col
        cols="12"
        sm="6"
        md="5"
        class="ms-auto"
      >
        <v-card
          class="faq-contact text-center pa-5"
          flat
        >
          <v-avatar
            rounded
            color="secondary"
            size="38"
            class="v-avatar-light-bg mb-6"
          >
            <v-btn
              icon
              @click="showIntercom"
            >
              <v-icon
                class="text--primary"
                size="24"
              >
                {{ icons.mdiForumOutline }}
              </v-icon>
            </v-btn>
          </v-avatar>

          <p class="text-xl text--primary font-weight-medium mb-2">
            <a @click="showIntercom">Click Here to open Chat</a>
          </p>

          <p class="text-sm mb-0">
            Message us now to help you faster
          </p>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="5"
        class="me-auto"
      >
        <v-card
          class="faq-contact text-center pa-5"
          flat
        >
          <v-avatar
            rounded
            color="secondary"
            size="38"
            class="v-avatar-light-bg mb-6"
          >
            <v-btn
              icon
              href="mailto:info@pharmaguide.ca"
            >
              <v-icon
                class="text--primary"
                size="24"
              >
                {{ icons.mdiEmailOutline }}
              </v-icon>
            </v-btn>
          </v-avatar>

          <p class="text-xl text--primary font-weight-medium mb-2">
            <a href="mailto:info@pharmaguide.ca">info@pharmaguide.ca</a>
          </p>

          <p class="text-sm mb-0">
            Best way to get answer faster!
          </p>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
/* eslint-disable operator-linebreak */

import {
  mdiMagnify,
  mdiHelpCircleOutline,
  mdiAccountDetailsOutline,
  mdiViewDashboardVariant,
  mdiEmailOutline,
  mdiForumOutline,
  mdiTruck,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
  name: 'SupportVideos',
  components: { vueVimeoPlayer },
  setup() {
    const knowledgeBaseSearchQuery = ref('')

    const faqQiestions = [
      {
        title: 'Introduction',
        characterSize: '400',
        desc: '(1 of 5)',
        length: '4:47',
        subtitle: 'Welcome to Phox Cloud',
        videoId: '669597464',
        icon: mdiHelpCircleOutline,
        character: require('@/assets/images/screenshots/phoxDash2.png'),
      },
      {
        characterSize: '400',
        title: 'Adaptation',
        desc: '(2 of 5)',
        length: '1:23',
        subtitle: 'How to use adaptation Form',
        videoId: '669597464',
        icon: mdiAccountDetailsOutline,
        character: require('@/assets/images/screenshots/phoxDash.png'),
      },
      {
        characterSize: '400',
        title: 'Dashboards',
        desc: '(3 of 5)',
        length: '2:47',
        subtitle: 'Product related questions.',
        videoId: '669597464',
        icon: mdiViewDashboardVariant,
        character: require('@/assets/images/screenshots/phoxSupport.png'),
      },
      {
        characterSize: '400',
        title: 'Medscheck',
        desc: '(4 of 5)',
        length: '7:32',
        subtitle: 'Increase loyalty and Optimize Outcomes',
        videoId: '669597464',
        icon: mdiTruck,
        character: require('@/assets/images/screenshots/phoxWiki.png'),
      },
      {
        characterSize: '400',
        title: 'COVID-19 Antigen Test',
        desc: '(5 of 5)',
        length: '2:42',
        subtitle: 'Generate a COVID-19 Antigen Test Report',

        // videoId: 'HY2dA6O8LMI',
        videoId: '669597464',
        icon: mdiTruck,
        character: require('@/assets/images/screenshots/referafriendsmall.png'),
      },
    ]

    const filteredKB = computed(() => {
      const knowledgeBaseSearchQueryLower = knowledgeBaseSearchQuery.value.toLowerCase()

      return faqQiestions.filter(
        item =>
          // eslint-disable-next-line implicit-arrow-linebreak
          item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower),

        // item.qAndA.filter(queObj => queObj.question.toLowerCase().includes(knowledgeBaseSearchQueryLower)).length,
      )
    })

    return {
      faqQiestions,
      knowledgeBaseSearchQuery,
      filteredKB,
      icons: {
        mdiMagnify,
        mdiEmailOutline,
        mdiForumOutline,
      },
    }
  },
  data() {
    return {
      videoId: '669597464',
      videoKey: 0,
      itemtitle: 'Introduction',
      itemdesc: '(1 of 5)',
      itemlength: '1:23',
      height: 500,
      options: {
        muted: true,
        autoplay: false,
      },
      playerReady: false,
    }
  },
  methods: {
    onReady() {
      this.playerReady = true
      console.log(this.playerReady)
    },
    play() {
      this.$refs.player.play()
    },
    pause() {
      this.$refs.player.pause()
    },
    scrollToForm() {
      document.getElementById('video-container').scrollIntoView({
        behavior: 'smooth',
      })
    },
    playVideo(videoId, item) {
      // const element = this.$refs.div1
      // const top = element.offsetTop
      // window.scrollTo(0, top)
      this.videoId = videoId
      this.itemtitle = item.title
      this.itemdesc = item.desc
      this.itemlength = item.length
      this.options.autoplay = true
      this.videoKey += 1

      this.playing()
    },

    playing() {
      this.scrollToForm()

      console.log('o/ we are watching!!!')
    },
    showIntercom() {
      Intercom('showNewMessage', 'Hey, I would like some help for')
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/supportvideos.scss';
</style>
